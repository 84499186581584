/* eslint-disable max-len */
import classNames from 'classnames';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import { Svgicon } from 'components/Svgicon/Svgicon';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'services/mobile';

const redirectToUrl = (newUrl: boolean) => {
  const newWindow = window.open('', '_blank');
  const url = newUrl ? 'https://portal.orcainc.com/' : 'https://app.servc.co.il/';
  if (newWindow) {
    newWindow.location.href = url;
  }
};

const isOldDomain = window.location.hostname.includes('servc.co.il');

export const MovementNotification = () => {
  const [
    showDescription,
    setShowDescription,
  ] = useState(!isMobile());
  const navigate = useNavigate();
  const navigateToResetPassword = () => {
    sessionStorage.setItem('canResetPassword', 'true');
    navigate('/auth/forgot-password');
  };

  const title =
    <>Investor Portal is moving to <span className='cursor-pointer' onClick={() => redirectToUrl(true)}><u>portal.orcainc.com!</u></span></>;
  const description =
    <>
      Your credentials stay the same. <span className='cursor-pointer' onClick={() => redirectToUrl(false)}><u>app.servc.co.il</u></span> will be available till October 30th 2024 and will automatically redirect to <span className='cursor-pointer' onClick={() => redirectToUrl(true)}><u>portal.orcainc.com</u></span> following this date.
    </>;
  const underDescription = isOldDomain ?
    <>
      As always we are here for you at <u>helpdesk@orcainc.com.</u>
    </>
    : <>
      Should you experience trouble logging in, you can <span className='cursor-pointer' onClick={navigateToResetPassword}><u>reset your password</u></span> below or contact us at <u>helpdesk@orcainc.com.</u>
    </>;
  const containerClasses = classNames(
    'w-full rounded-main bg-main-700 pl-[1rem] pr-[1.25rem] py-[1.25rem] dt:p-[1.25rem] dt:bg-[url(/src/assets/images/browser-png.png)] bg-no-repeat bg-right-bottom',
    {
      '!bg-right dt:bg-[url(/src/assets/images/new-browser.svg)] bg-origin-content': !isOldDomain && showDescription && !isMobile(),
      '!bg-right-bottom bg-[url(/src/assets/images/browser-transparent.svg)]': showDescription && isMobile(),
      '!p-[0.5rem] max-h-[2rem] items-center !self-center !bg-none': !showDescription,
    },
  );

  const closeNotification = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowDescription(false);
  };

  return <div className={containerClasses} onClick={() => setShowDescription(true)}>
    <div className='relative flex w-full items-start gap-[1rem]  dt:gap-6'>
      {showDescription && isOldDomain && !isMobile() && <div>
        <Svgicon color='url(#paint0_linear_4095_66383)' id='browser' />
      </div>}
      <div className='flex w-full max-w-[25rem] flex-col items-start gap-[0.75rem]'>
        <div className='flex w-full flex-col gap-[0.75rem]'>
          <div className='flex w-full items-center justify-between gap-8'>
            {showDescription ?
              <p className='m-0 text-font-1 font-bold text-white-999'>{title}</p>
              : <div className='flex items-center gap-[0.5rem]'>
                <Svgicon className='size-[0.75rem]' color='white' id='browser' />
                <p className='m-0 text-font-3 font-medium text-white-999'>{title}</p>
              </div>}
            {showDescription ? <div className='absolute right-[-1rem] top-[-1rem]' onClick={closeNotification}>
              <Svgicon className='size-6 text-white-999' id='close-1f' />
            </div> : <div className='absolute right-0 top-[-0.125rem]'>
              <Svgicon className='size-[1.25rem] rotate-90 text-white-999' id='arrow-right-3f' />
            </div>}
          </div>
          {showDescription && <>
            <p className='m-0 text-font-3 font-normal text-white-999'>{description}</p>
            <p className='m-0 text-font-4 font-normal text-white-999'>{underDescription}</p>
          </>}
        </div>
        {showDescription && isOldDomain &&
        <Button
          className='cursor-pointer !rounded-main !text-main-700'
          onClick={() => redirectToUrl(true)}
          text='Go to portal.orcainc.com' tooltipWrapperClass='!w-auto'
          type='white'
        />}
      </div>
    </div>
  </div>;
};
